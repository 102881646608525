import { utils } from 'ethers';
import { SiweMessage } from 'siwe';

import { SignType } from '@api';

import { getProcessEnvGlobalConfig } from '@shared/config/global';
import getSignTypeFlags from '@shared/helpers/web3/getSignTypeFlags';

const verifyEVMSignature = async ({
  address,
  signature,
  message,
  signType,
  nonce,
}: {
  address: string;
  message: string;
  signature: string;
  signType: SignType;
  nonce?: string;
}) => {
  const verificationResult = utils.verifyMessage(message, signature).toLowerCase();
  const isVerified = verificationResult === address.toLowerCase();

  if (!isVerified) {
    throw new Error('Signature verification failed: Address does not match');
  }

  const { isSIWE } = getSignTypeFlags(signType);

  if (isSIWE) {
    try {
      const siweMessage = new SiweMessage(message);
      const fields = await siweMessage.verify({ signature });

      if (fields.data.nonce !== nonce) {
        throw new Error('Signature verification failed: Invalid nonce.');
      }
    } catch (error) {
      if (getProcessEnvGlobalConfig('isDev')) {
        console.debug(error);
      }

      throw new Error('Signature verification failed: Invalid Message.');
    }
  }
};

export default verifyEVMSignature;
