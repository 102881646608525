import Cookies from 'js-cookie';

import { WalletType } from '@api';

import { storageKeys } from '@shared/constants';

const saveConnectionToCookies = (address: string, walletType: WalletType) => {
  Cookies.set(storageKeys.MSQ_CONNECTED_WALLET_ADDRESS, address);
  Cookies.set(storageKeys.MSQ_CONNECTED_WALLET_TYPE, walletType);
};

export default saveConnectionToCookies;
