import { isDesktop } from 'react-device-detect';
import { toast } from 'react-toastify';

import { getAccount, switchChain } from '@wagmi/core';

import { WalletType } from '@api';

import { wagmiConfig, WagmiConfigChain } from '@shared/common/providers/Web3Provider/wagmi';
import { DEFAULT_TARGET_CHAIN, namedWeb3Errors } from '@shared/constants';
import { normalizeError } from '@shared/helpers/displayErrorToast';
import getChainRequirements from '@shared/helpers/web3/getChainRequirements';
import launchAsyncToast from '@shared/helpers/web3/launchAsyncToast';

const switchChainUtil = async (props: {
  targetChain?: WagmiConfigChain;
  address: string;
  walletType: WalletType;
  requiredSqrAmount?: number | string;
}): Promise<ReturnType<typeof getChainRequirements> | undefined> => {
  const { targetChain = DEFAULT_TARGET_CHAIN, walletType, address, requiredSqrAmount } = props;
  const { chainId } = getAccount(wagmiConfig);
  const chain = wagmiConfig.chains.find((ch) => ch.id === chainId);

  if (chain === undefined) {
    throw new Error(namedWeb3Errors.RECONNECT_REQUEST);
  }

  const requirements = getChainRequirements(targetChain, requiredSqrAmount);

  if (chain.id !== targetChain.id) {
    const toastId = new Date().getTime();

    try {
      await Promise.race([
        switchChain(wagmiConfig, {
          chainId: targetChain.id,
        }),
        launchAsyncToast({
          walletType,
          address,
          toastId,
          title: 'Waiting for the user to switch the chain...',
          showExtraInfo: !isDesktop,
        }),
      ]);

      toast.dismiss(toastId);

      return await switchChainUtil(props);
    } catch (error) {
      toast.dismiss(toastId);

      const normalizedError = normalizeError(error);

      if (normalizedError.message === namedWeb3Errors.RETRY) {
        return switchChainUtil(props);
      }

      if (normalizedError.message === requirements.nativeBalance) {
        throw error;
      }

      throw new Error(requirements.chain);
    }
  }

  return requirements;
};

export default switchChainUtil;
