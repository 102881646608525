/**
 * Checks if the EVM extension is enabled.
 */
const isEvmExtensionActive = (): boolean => {
  return Boolean(typeof window.ethereum !== 'undefined');
};

/**
 * Checks if the Trust wallet browser extension is enabled.
 */
const isTrustWalletExtensionActive = (): boolean => {
  return Boolean(typeof window.ethereum !== 'undefined' && window.ethereum.isTrust);
};

/**
 * Checks if the Metamask wallet browser extension is enabled.
 */
const isMetamaskExtensionActive = (): boolean => {
  return Boolean(typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask);
};

/**
 * Checks if the OKX wallet browser extension is enabled.
 */
const isOKXWalletExtensionActive = (): boolean => {
  return Boolean(typeof window.okxwallet !== 'undefined');
};

export type EthereumProvider = Record<string, unknown>;

/**
 * Gets the Ethereum provider if EVM[EthereumVirtualMachine] wallet (e.g. Metamask) is installed.
 */
const getEthereumProvider = (): EthereumProvider | undefined => {
  return window.ethereum;
};

const evm = {
  isEvmExtensionActive,
  isTrustWalletExtensionActive,
  isOKXWalletExtensionActive,
  isMetamaskExtensionActive,
  getProvider: getEthereumProvider,
};

export default evm;
