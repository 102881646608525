import { Id, toast, ToastOptions } from 'react-toastify';

import { WalletType } from '@api';

import ToastButton from '@shared/common/components/ToastButton';
import { namedWeb3Errors } from '@shared/constants';
import getToastIcon from '@shared/helpers/web3/getToastIcon';
import getWalletActionStyles from '@shared/helpers/web3/getWalletActionStyles';
import getWalletToastButtonClassName from '@shared/helpers/web3/getWalletToastButtonClassName';
import truncate from '@shared/helpers/web3/truncate';

const launchAsyncToast = ({
  address,
  title = "Please approve the 'Sign In' request in your wallet...",
  description,
  toastId,
  walletType,
  showExtraInfo,
  hideRetryButton,
}: {
  address?: string;
  title?: string;
  description?: string;
  showExtraInfo?: boolean;
  hideRetryButton?: boolean;
  toastId: Id;
  walletType: WalletType;
}): Promise<string> =>
  new Promise((resolve, reject) => {
    const icon = getToastIcon(walletType);
    const style = getWalletActionStyles(walletType);
    const buttonClassname = getWalletToastButtonClassName(walletType);
    const toastContent = (
      <div className="flex flex-col items-center justify-center gap-14">
        <div className="flex flex-col items-center justify-center gap-6">
          <b className="text-center">{title}</b>

          {showExtraInfo && (
            <p className="text-center text-sm font-medium">
              Request has been sent to your wallet app/extension.
              <br />
              {address && <i>{truncate(address, { start: 7 })}</i>}
            </p>
          )}

          {description && <span className="text-center text-sm font-medium">{description}</span>}
        </div>

        {showExtraInfo && (
          <>
            {!hideRetryButton && (
              <ToastButton
                size="lg"
                onClick={() => {
                  reject(new Error(namedWeb3Errors.RETRY));
                }}
                toastId={toastId}
                minWidth={175}
                className={buttonClassname}
              >
                {icon}
                Try again
              </ToastButton>
            )}

            <ToastButton
              size="lg"
              onClick={() => {
                reject(new Error(namedWeb3Errors.REQUEST_CANCELLED));
              }}
              toastId={toastId}
              variant="ghost"
              className="opacity-60 hover:opacity-100 !text-neutral-50 bg-transparent"
            >
              Cancel
            </ToastButton>
          </>
        )}
      </div>
    );
    const toastOptions: ToastOptions = {
      toastId,
      style: {
        ...style,
        ...{ paddingBlock: showExtraInfo ? '32px 16px' : '' },
      },
      icon: false,
    };

    toast.loading(toastContent, toastOptions);
  });

export default launchAsyncToast;
