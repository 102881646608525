import { WalletType } from '@api';

import { walletIconMappings } from '@shared/constants';

const getToastIcon = (walletType: WalletType) => {
  const Icon = walletIconMappings[walletType];

  return (
    <span className="icon">
      <Icon />
    </span>
  );
};

export default getToastIcon;
