import { getAddress } from 'ethers/lib/utils';

import { namedWeb3Errors } from '@shared/constants';

const getOriginWalletAddressCase = (rawAddress?: string): string => {
  if (!rawAddress) {
    throw new Error(namedWeb3Errors.EMPTY_ADDRESS);
  }

  // EIP55 Address (Original address case with lower and uppercase letters)
  return getAddress(rawAddress);
};

export default getOriginWalletAddressCase;
