// Possibly was working only on old wagmi versions
const handleWalletConnectDeepLink = () => {
  const isIOS = () =>
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
      navigator.platform,
    ) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
  const WALLET_CONNECT_DEEPLINK_CHOICE = 'WALLETCONNECT_DEEPLINK_CHOICE'; // It's not a typo
  const walletDeeplinkMappings = {
    'https://link.trustwallet.com/wc': { name: 'Trust Wallet', href: 'trust://' },
    'https://metamask.app.link/wc': { name: 'Metamask', href: 'metamask://' },
  };
  const deepLink = window.localStorage.getItem(WALLET_CONNECT_DEEPLINK_CHOICE);

  if (deepLink) {
    try {
      const { href } = JSON.parse(deepLink);

      if (isIOS() && href in walletDeeplinkMappings) {
        const newDeepLink = walletDeeplinkMappings[href as keyof typeof walletDeeplinkMappings];

        window.localStorage.setItem(WALLET_CONNECT_DEEPLINK_CHOICE, JSON.stringify(newDeepLink));
      }
    } catch (error) {
      console.error('CustomError: WalletConnect force redirect error', error);
    }
  }
};

export default handleWalletConnectDeepLink;
