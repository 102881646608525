import { WalletType } from '@api';

const getWalletToastButtonClassName = (walletType: WalletType): string => {
  const walletSpecificStyles: Record<WalletType, string> = {
    Metamask: 'bg-[#fa7a02] hover:bg-[#de6c00]',
    WalletConnect: '',
    WalletConnect2: '',
    OKX: '',
    Phantom: '',
    Trust: '',
    EVM: '',
    Passkey: '',
  };

  return walletSpecificStyles[walletType] ?? '';
};

export default getWalletToastButtonClassName;
