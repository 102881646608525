import { toast } from 'react-toastify';

import { WalletType } from '@api';

import getWalletActionStyles from '@shared/helpers/web3/getWalletActionStyles';

/**
 *  That toast needed to delay sending another web3 request for continuous redirects working fine
 *  This code will allow code to go only when user are returned to the mobile browser site
 **/
const launchConfirmToast = async ({
  walletType,
  isDesktop: isDesktopUA,
}: {
  walletType: WalletType;
  isDesktop: boolean;
}) => {
  if (isDesktopUA || walletType !== 'WalletConnect') {
    return;
  }

  const style = getWalletActionStyles(walletType);
  // Needed due to when you launch a toast with the same ID(s) which just was dismissed it won't display it
  const toastId = new Date().getTime(); // ConfirmLoginBySigning
  const launchToast = new Promise((resolve) => {
    toast.loading('Loading...', {
      toastId,
      style,
    });

    const passUser = () => {
      if (!document.hidden) {
        setTimeout(() => {
          toast.dismiss(toastId); // Dismiss inside the promise
          resolve(null);
          window.removeEventListener('visibilitychange', passUser);
        }, 1000);
      }
    };

    window.addEventListener('visibilitychange', passUser);
  });

  if (document.hidden) {
    await launchToast;
  }
};

export default launchConfirmToast;
