import { WalletType } from '@api';

const getWalletTypeFlags = (walletType: WalletType) => ({
  isEVM: walletType === 'EVM',
  isMetamask: walletType === 'Metamask',
  isOKX: walletType === 'OKX',
  isPhantom: walletType === 'Phantom',
  isTrust: walletType === 'Trust',
  isWalletConnect: walletType === 'WalletConnect',
  isPasskey: walletType === 'Passkey',
  isWalletConnect2: walletType === 'WalletConnect2',
});

export default getWalletTypeFlags;
