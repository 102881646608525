import { useCallback, useEffect, useRef } from 'react';

import { useWeb3Modal, useWeb3ModalState } from '@web3modal/wagmi/react';

import { Address } from 'viem';
import { useAccount, useDisconnect } from 'wagmi';

const useWeb3ModalConnectWalletAsync = () => {
  const { open: openWeb3Modal } = useWeb3Modal();
  const { disconnectAsync } = useDisconnect();

  const { open: isWeb3ModalOpen } = useWeb3ModalState();
  const { address: accountAddress, chain } = useAccount();
  const triggerConnectRef = useRef<
    ((params: { address: Address | undefined; chain: typeof chain } | null) => void) | null
  >(null);

  useEffect(() => {
    if (!isWeb3ModalOpen && triggerConnectRef.current) {
      triggerConnectRef.current(accountAddress ? { address: accountAddress, chain } : null);

      triggerConnectRef.current = null;
    }
  }, [accountAddress, chain, isWeb3ModalOpen]);

  return useCallback(async () => {
    await disconnectAsync();

    await openWeb3Modal({
      view: 'Connect',
    });

    return new Promise<{ address: Address | undefined; chain: typeof chain } | null>((res) => {
      triggerConnectRef.current = res;
    });
  }, [openWeb3Modal, disconnectAsync]);
};

export default useWeb3ModalConnectWalletAsync;
