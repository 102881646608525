import { isDesktop } from 'react-device-detect';

import { WalletType } from '@api';

import evm from '@shared/helpers/web3/evm';
import solana from '@shared/helpers/web3/solana';

const checkConnectorReadiness = (walletType: WalletType) => {
  switch (walletType) {
    case 'EVM':
      return {
        isReady: isDesktop ? evm.isEvmExtensionActive() : false,
        errorMessage:
          'To use this feature, you need an EVM wallet extension (e.g., Metamask) installed in your browser or connect using WalletConnect.',
      };
    case 'Phantom':
      return {
        isReady: isDesktop ? solana.isPhantomWalletInstalled() : false,
        errorMessage: 'To proceed, please install the Phantom wallet extension in your browser.',
      };
    case 'Trust':
      return {
        isReady: isDesktop ? evm.isTrustWalletExtensionActive() : false,
        errorMessage: 'To proceed, please install the Trust wallet extension in your browser.',
      };

    case 'Metamask':
      return {
        isReady: isDesktop ? evm.isMetamaskExtensionActive() : false,
        errorMessage: 'To proceed, please install the Metamask extension in your browser.',
      };
    case 'OKX':
      return {
        isReady: isDesktop ? evm.isOKXWalletExtensionActive() : false,
        errorMessage: 'To proceed, please install the OKX wallet extension in your browser.',
      };
    default:
      return {
        isReady: true,
        errorMessage: '',
      };
  }
};

export default checkConnectorReadiness;
