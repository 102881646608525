/**
 * NOTE: do not use any special symbols like "…", because it can break metamask SIWE signing
 * (truncateAddress used for creating SIWE messages)
 */
const truncate = (str: string, { start = 6, end = 4, ellipsis = '...' } = {}) => {
  if (str.length <= start + end) {
    return str;
  }

  return `${str.slice(0, start)}${ellipsis}${str.slice(-end)}`;
};

export default truncate;
