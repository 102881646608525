import { CSSProperties } from 'react';

import { WalletType } from '@api';

const getWalletActionStyles = (walletType: WalletType) => {
  const defaultStyles = {
    borderRadius: '8px',
    background: '#130e3a',
    color: '#fff',
  };

  const walletSpecificStyles: Record<WalletType, CSSProperties> = {
    WalletConnect: {
      ...defaultStyles,
      background: 'linear-gradient(270deg, #47a1ff 0%, #0059c3 100%)',
    },
    WalletConnect2: {
      ...defaultStyles,
      background: 'linear-gradient(270deg, #47a1ff 0%, #0059c3 100%)',
    },
    Phantom: {
      ...defaultStyles,
      background: 'linear-gradient(90deg, #926ccf 0%, #523fc1 100%)',
    },
    Metamask: {
      ...defaultStyles,
      background: 'linear-gradient(90deg, #F6851B 0%, #f9a461 100%)',
    },
    OKX: {
      ...defaultStyles,
      background: 'linear-gradient(90deg, #000000 0%, #000000b3 100%)',
    },
    Trust: {
      ...defaultStyles,
      background: 'linear-gradient(90deg, #0500ff 0%, #45f899 100%)',
    },
    Passkey: defaultStyles,
    EVM: defaultStyles,
  };

  return defaultStyles || walletSpecificStyles[walletType];
};

export default getWalletActionStyles;
